<template>
  <v-card class="pa-4" elevation="1" rounded="lg" height="100%">
    <!-- Title -->
    <v-card-title class="mb-1 ft-700">
      {{ $vuetify.lang.t('$vuetify.widget_callback.title') }}
    </v-card-title>
    <!-- Salutation -->
    <v-card-text>
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-row dense>
          <v-col cols="12" md="8" lg="6">
            <v-radio-group v-model="radioGroup" row>
              <v-radio v-for="n in 3" :key="n" :value="n" color="accent">
                <template #label>
                  <div class="ft-500">{{ raidoOptions[n - 1] }}</div>
                </template>
              </v-radio>
            </v-radio-group>
          </v-col>
        </v-row>
        <!-- Row 1 -->
        <v-row dense>
          <v-col cols="12" md="6" lg="4" class="pr-3">
            <v-text-field
              v-model="firstName"
              :rules="fieldRules"
              :label="$vuetify.lang.t('$vuetify.widget_callback.first_name')"
              required
              outlined
              color="blue"
              background-color="text_area_background"></v-text-field>
          </v-col>
          <v-col cols="12" md="6" lg="4" class="pr-3">
            <v-text-field
              v-model="lastName"
              :rules="fieldRules"
              :label="$vuetify.lang.t('$vuetify.widget_callback.last_name')"
              required
              outlined
              color="blue"
              background-color="text_area_background"></v-text-field>
          </v-col>
        </v-row>
        <!-- Row 2 -->
        <v-row dense>
          <v-col cols="12" md="6" lg="4" class="pr-3">
            <v-text-field
              v-model="phone"
              :rules="fieldRules"
              :label="$vuetify.lang.t('$vuetify.widget_callback.phone')"
              required
              outlined
              color="blue"
              background-color="text_area_background"></v-text-field>
          </v-col>
          <v-col cols="12" md="6" lg="4" class="pr-3">
            <v-text-field
              v-model="email"
              :rules="emailRules"
              :label="$vuetify.lang.t('$vuetify.widget_email.email')"
              required
              outlined
              color="blue"
              background-color="text_area_background"></v-text-field>
          </v-col>
        </v-row>
        <!-- Row 3 -->
        <v-row dense>
          <v-col cols="12" md="6" lg="4" class="pr-3">
            <v-text-field
              v-model="date"
              :rules="dateRules"
              :label="$vuetify.lang.t('$vuetify.widget_callback.preferred_date')"
              required
              outlined
              color="blue"
              type="date"
              :min="new Date().toISOString().slice(0, 10)"
              background-color="text_area_background"></v-text-field>
          </v-col>
          <!-- <v-col cols="12" md="6" lg="4" class="pr-3">
            <v-select
              v-model="time"
              :rules="fieldRules"
              :label="$vuetify.lang.t('$vuetify.widget_callback.preferred_time')"
              required
              outlined
              color="blue"
              type="time"
              background-color="text_area_background"
              :items="itemsTime"></v-select>
          </v-col> -->
        </v-row>
        <!-- Send button -->
        <v-row>
          <v-col cols="12" md="8" lg="6">
            <v-btn
              height="45"
              :disabled="!valid"
              color="accent"
              class="mr-4 mb-1 text-none ft-700"
              @click="validate">
              {{ $vuetify.lang.t('$vuetify.widget_callback.button_text_callback') }}
            </v-btn>

            <!-- <v-btn
              height="45"
              color="primary"
              class="mr-4 mb-1 text-none ft-700"
              @click="reset"
            >
              {{
                $vuetify.lang.t("$vuetify.widget_callback.button_text_reset")
              }}
            </v-btn> -->
          </v-col>
        </v-row>
      </v-form>
      <!-- Alert -->
      <v-row>
        <v-col cols="12" md="8" lg="6">
          <v-alert
            v-show="showAlert"
            class="mt-2"
            :color="alertType == 'error' ? 'error' : 'accent'"
            dense
            :type="alertType">
            {{ alertMessage }}
          </v-alert>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { postCallback } from '../../api/api'
import { mapState } from 'vuex'
import { emailRegex } from '../../api/util'

export default {
  data() {
    return {
      valid: true,
      firstName: '',
      lastName: '',
      phone: '',
      date: '',
      time: 'beliebig',
      itemsTime: [
        'beliebig',
        '9:00-9:30',
        '9:30-10:00',
        '10:00-10:30',
        '10:30-11:00',
        '11:00-11:30',
        '11:30-12:00',
        '12:00-12:30',
        '12:30-13:00',
        '13:00-13:30',
        '13:30-14:00',
        '14:00-14:30',
        '14:30-15:00',
        '15:00-15:30',
        '15:30-16:00',
        '16:00-16:30',
        '16:30-17:00',
      ],
      email: '',
      fieldRules: [v => !!v || this.$vuetify.lang.t('$vuetify.widget_callback.text_required')],
      dateRules: [
        value => {
          if (!value) return 'Dies ist ein Pflichtfeld'
          let date = new Date(value)
          const today_midnight = new Date()
          today_midnight.setHours(0, 0, 0, 0)
          if (date < today_midnight) return 'Bitte wähle ein Datum in der Zukunft'
          if (date.getDay() == 6 || date.getDay() == 0)
            return 'Bitte nur Tage von Montag bis Freitag wählen'
          return true
        },
      ],
      emailRules: [
        v => !!v || this.$vuetify.lang.t('$vuetify.widget_email.text_required'),
        v => !v || emailRegex.test(v) || this.$vuetify.lang.t('$vuetify.widget_email.syntax'),
      ],
      radioGroup: 1,
      raidoOptions: [
        this.$vuetify.lang.t('$vuetify.widget_callback.radio_woman'),
        this.$vuetify.lang.t('$vuetify.widget_callback.radio_man'),
        this.$vuetify.lang.t('$vuetify.widget_callback.radio_other'),
      ],
      showAlert: false,
      alertType: 'success',
      alertMessage: 'Anfrage erfolgreich verschickt!',
    }
  },
  computed: {
    ...mapState({
      customer_selected: state => state.customer_selected,
    }),
  },
  mounted() {
    // Set initial data
    this.firstName = this.customer_selected.first_name
    this.lastName = this.customer_selected.last_name
    this.email = this.customer_selected.email
    switch (this.customer_selected.salutation) {
      case 'Frau':
        this.radioGroup = 1
        break
      case 'Herr':
        this.radioGroup = 2
        break
      default:
        this.radioGroup = 3
        break
    }
  },
  methods: {
    validate() {
      if (this.$refs.form.validate()) {
        // Create the payload
        let payload = {
          first_name: this.firstName,
          last_name: this.lastName,
          phone: this.phone,
          email: this.email,
          date: this.date,
          time: this.time,
        }
        switch (this.radioGroup) {
          case 1:
            payload.salutation = 'Frau'
            break
          case 2:
            payload.salutation = 'Herr'
            break
          default:
            break
        }
        // Call API
        postCallback(payload).then(res => {
          // Show corresponding alert
          if (res) {
            this.showAlert = true
            this.alertType = 'success'
            this.alertMessage = 'Anfrage erfolgreich verschickt!'
          } else {
            this.showAlert = true
            this.alertType = 'error'
            this.alertMessage = 'Es gab ein Problem beim verschicken der Nachricht.'
          }
        })
      }
    },
    reset() {
      this.$refs.form.reset()
    },
    resetValidation() {
      this.$refs.form.resetValidation()
    },
  },
}
</script>

<style scoped>
.hidden-sm-and-down .v-icon {
  color: white !important;
}
</style>
