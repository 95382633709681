import { render, staticRenderFns } from "./WidgetContact.vue?vue&type=template&id=1bca1341&scoped=true"
import script from "./WidgetContact.vue?vue&type=script&lang=js"
export * from "./WidgetContact.vue?vue&type=script&lang=js"
import style0 from "./WidgetContact.vue?vue&type=style&index=0&id=1bca1341&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1bca1341",
  null
  
)

export default component.exports