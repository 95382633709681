import { render, staticRenderFns } from "./AcceptedRejectedComponent.vue?vue&type=template&id=3494e749&scoped=true"
import script from "./AcceptedRejectedComponent.vue?vue&type=script&lang=js"
export * from "./AcceptedRejectedComponent.vue?vue&type=script&lang=js"
import style0 from "./AcceptedRejectedComponent.vue?vue&type=style&index=0&id=3494e749&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3494e749",
  null
  
)

export default component.exports